import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import FlexibleContent from "../../components/FlexibleContent";
import Layout from "../../layouts";
import SEO from "../../components/seo/SEO";
import { useSiteMetadata } from "../../hooks/use-site-metadata";
import { loadScript, unloadScript, pageTitle } from "../../utils/helpers";

function Careers(props) {
  useEffect(() => {
    //load scripts
    loadScript(
      "https://unpkg.com/masonry-layout@4/dist/masonry.pkgd.min.js",
      "masonry-pkgd",
      false,
      false
    );

    return function cleanup() {
      unloadScript("masonry-pkgd");
    };
  });

  const currentPage = props.data.wordpressPage;
  const site = useSiteMetadata();

  const currentPageTitle = currentPage.yoast_meta.yoast_wpseo_title
    ? currentPage.yoast_meta.yoast_wpseo_title
    : pageTitle({
        site: site.title,
        page: currentPage,
      });

  return (
    <Layout>
      <Helmet bodyAttributes={{ id: `page-${currentPage.slug}` }}>
        <title>{currentPageTitle}</title>
      </Helmet>
      <SEO node={currentPage} site={site} />
      <FlexibleContent currentPage={currentPage} />
      <section className="section-body">
        <div className="content">
          <div className="content--main">
            <h4 className="text-align-center">{`We’re Hiring`}</h4>
            <p>
              <i>fully integrated</i>
              <br />
              <i>single brand</i>
              <br />
              <i>always evolving</i>
              <br />
              <i>connected</i>
              <br />
              <i>founder-led</i>
              <br />
              <i>digital-first</i>
              <br />
              <i>seamless access</i>
              <br />
              <i>foundationally different</i>
            </p>
            <p>Check back soon for a new careers page experience.</p>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Careers;

export const pageQuery = graphql`
  {
    wordpressPage(title: { eq: "Careers" }) {
      title
      slug
      ...Hero
      ...Info
      ...Grid
      ...Interstitial
      ...Benefits
      ...SEO
    }
  }
`;
